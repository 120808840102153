<template>
  <div class="card">
    <CardImage
      :selected="selected"
      :title="card.title"
    />
    <div>
      <h3 class="card__title">
        {{ card.title }}
      </h3>
      <p class="card__text">
        {{ card.text }}
      </p>
    </div>
  </div>
</template>

<script>
import CardImage from '@/components/slider/SliderImage.vue';

export default {
  name: 'Card',

  components: {
    CardImage,
  },

  props: {
    selected: {
      type: Number,
      required: true,
    },
  },

  computed: {
    card() {
      switch(this.selected) {
        case 1:
          return {
            title: 'Agendamento de serviços e consultas',
            text: 'Evite filas e melhore a eficiência dos agendamentos da sua cidade, web e app. Disponibilize agendamentos para diversos serviços da cidade, como: vaga de emprego, vacina, IPTU, RG, Obras, Procon, Aluguel de Quadras e muito mais.'
          };
        case 2:
          return {
            title: 'Central de Serviços',
            text: 'Área para abertura de solicitações de serviços de forma mais rápida, prática e transparente. Crie dentro da plataforma todos os serviços da prefeitura em poucos passos. Uma garantia da conformidade com a Lei nº13.460.'
          };
        case 3:
          return {
            title: 'Procon',
            text: 'Disponibilize todo o serviço do Procon pela web ou app, desde abertura e acompanhamento de reclamações, pesquisa de preço, agendamento de atendimento presencial/online a consulta do Código do Consumidor.',
          };
        case 4:
          return {
            title: 'Aplicativo da Cidade',
            text: 'Sua cidade conectada através de um app personalizado e disponível em Android e iOS. Todos os serviços da prefeitura na palma da mão do cidadão. Um contato direto para envio de notificações e comunicação.',
          };
        case 5:
          return {
            title: 'Internet Pública',
            text: 'Tenha um serviço público de wi-fi para toda a cidade. Infraestrutura própria com relatório de acessos e possibilidade de envio de notificações e pesquisa para toda a população local.',
          };
        case 6:
          return {
            title: 'Chatbot de Atendimento',
            text: 'Tenha um serviço inteligente de atendimento automatizado e virtual. Reduza o número de demandas nos canais da prefeitura e resolva rapidamente dúvidas recorrentes. Mais acessibilidade para o cidadão.',
          };
        case 7:
          return {
            title: 'Comércio Local',
            text: 'Estimule o comércio local dando acesso a uma plataforma completa para divulgação e venda de produtos. Incentive a regularização de microempresas e ajude a fortalecer a economia da sua cidade.',
          };
        case 8:
          return {
            title: 'Conta Única',
            text: 'Armazene e centralize os dados dos cidadãos. Com um único acesso, a pessoa tem acesso a todos os serviços da sua prefeitura. Controle e admiinistre os dados por meio de um painel web.',
          };
      }

      return null;
    },
  }
};
</script>

<style lang="scss" scoped>
.card {
  padding: 1.5rem;
  width: 100%;

  @include screen(tablet-big-up) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: $gray-500;
    font-size: $text-3xl;
    font-weight: $font-bold;
    margin-bottom: 0.5rem;
    text-align: center;

    @include screen(tablet-big-up) {
      font-size: 2.5rem;
      max-width: 25rem;
      min-width: 20rem;
      text-align: start;
      margin-right: 5rem;
    }
  }

  &__text {
    color: $gray-500;
    font-size: $text-base;
    text-align: center;

    @include screen(tablet-big-up) {
      font-size: $text-lg;
      max-width: 25rem;
      min-width: 20rem;
      text-align: start;
      margin-right: 5rem;
    }
  }
}
</style>