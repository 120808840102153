<template>
  <section class="banner">
    <div class="banner__text">
      <h2 class="banner__text__title">
        Como o UpCities pode ajudar minha cidade?
      </h2>
      <p class="banner__text__info">
        O UpCities é uma produto digital para cidades que querem se conectar.
        Intuitivo e prático, que
        <span class="banner__text__info--bold">
          entrega os serviços da cidade para o cidadão, em qualquer momento.
        </span>
        Feito para cidades que buscam inovação e conectividade e que anseiam facilitar a vida das pessoas.
      </p>
    </div>
    <iframe
      class="banner__player"
      src="https://www.youtube.com/embed/b81W5d-viJ8"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </section>
</template>

<script>
export default {
  name: 'VideoBanner',
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  margin: 0 0 4rem;
  padding: 0 4%;

  @include screen(tablet-big-up) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin: 5rem 0;
    padding: 0 8%;
  }

  &__text {
    color: $gray-text;

    @include screen(tablet-big-up) {
      margin-left: 3.75rem;
      max-width: 20rem;
    }

    &__title {
      font-size: $text-3xl;
      font-weight: $font-bold;
      margin-bottom: 1rem;
      line-height: 2.5rem;

      @include screen(tablet-big-up) {
        font-size: 2.5rem;
        line-height: 3.3rem;
      }
    }

    &__info {
      font-size: $text-base;
      margin-bottom: 2rem;

      @include screen(tablet-big-up) {
        font-size: $text-lg;
        margin-bottom: 0;
      }

      &--bold {
        font-weight: $font-bold;
      }
    }
  }

  &__player {
    margin: auto;
    width: 100%;
    max-width: 45rem;
    aspect-ratio: 16 / 9;

    @include screen(tablet-big-up) {
      margin: 0;
    }
  }
}
</style>