<template>
  <transition
    v-if="!accepted"
    name="cookie-banner--slide-up"
  >
    <div class="cookie-banner">
      <p class="cookie-banner__text">
        Para oferecer a melhor experiência em nosso site, utilizamos cookies em conformidade
        com a Lei Geral de Proteção de Dados (LGPD). Ao continuar navegando, você concorda com
        nosso uso de cookies e com nossa
        <router-link
          :to="{ name: 'PrivacyPolicyUpCities' }"
          class="cookie-banner__link"
        >
          Política de Privacidade
        </router-link>.
      </p>
      <button
        class="cookie-banner__button"
        type="button"
        @click="acceptTerms"
      >
        Aceitar
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CookieConsentBanner',
  data() {
    return {
      accepted: false
    };
  },
  beforeMount() {
    this.accepted = localStorage.getItem('cookieConsent') === 'true';
  },
  methods: {
    acceptTerms() {
      this.accepted = true;
      localStorage.setItem('cookieConsent', 'true');
    }
  }
};
</script>

<style lang="scss" scoped>
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($black, 80%);
  color: $white;
  padding: 1rem 4%;
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
  align-items: center;
  z-index: 1000;
  font-size: $text-sm;

  @include screen(tablet-big-up) {
    padding: 2rem 8%;
    column-gap: 6rem;
  }

  &__link {
    color: $white;
    text-decoration: underline;
    cursor: pointer;
  }

  &__button {
    background-color: $gray-100;
    border-radius: $radius-md;
    color: $gray-text;
    font-weight: $font-semibold;
    padding: 1rem 1.5rem;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #ccc;
    }
  }

  &--slide-up-enter-active,
  &--slide-up-leave-active {
    transition: transform 0.3s ease;
  }

  &--slide-up-enter,
  &--slide-up-leave-to {
    transform: translateY(100%);
  }
}
</style>