<template>
  <div
    v-if="visible"
    class="background"
  >
    <div class="modal">
      <button
        class="modal__close"
        @click="$emit('close')"
      >
        <SvgElement
          name="Close"
        />
      </button>
      <div class="modal__image">
        <SvgElement
          class="modal__image__check"
          name="Checkmark"
        />
      </div>
      <h3 class="modal__title">
        Seu formulário foi<br>enviado com sucesso!
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailModal',

  props: {
    visible: {
      type: Boolean,
      required: true,
    }
  }
};
</script>

<style lang="scss" scoped>
.background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba($color: $black, $alpha: 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .modal {
    background-color: $gray-100;
    padding: 1.5rem;
    border-radius: $radius-lg;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    -webkit-animation: shadow-drop-2-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: shadow-drop-2-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    &__close {
      color: $gray-200;
    }

    &__image {
      width: 100%;
      height: 5.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;

      &__check {
        background-color: $primary-btn;
        border-radius: $radius-full;
        height: 5.875rem;
        width: 5.875rem;
        padding: 1.7rem 1.5rem;
        color: $white;
        -webkit-animation: rotate-in-2-fwd-ccw 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: rotate-in-2-fwd-ccw 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }

    &__title {
      font-size: $text-2xl;
      color: $gray-500;
      width: 100%;
      text-align: center;
      padding: 1rem 3rem 2.5rem;
    }
  }
}

@-webkit-keyframes shadow-drop-2-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: translateZ(50px) translateY(-12px);
    transform: translateZ(50px) translateY(-12px);
    -webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
    box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

@keyframes shadow-drop-2-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: translateZ(50px) translateY(-12px);
    transform: translateZ(50px) translateY(-12px);
    -webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
    box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

@-webkit-keyframes rotate-in-2-fwd-ccw {
  0% {
    -webkit-transform: translateZ(-200px) rotate(45deg);
    transform: translateZ(-200px) rotate(45deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) rotate(0);
    transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}

@keyframes rotate-in-2-fwd-ccw {
  0% {
    -webkit-transform: translateZ(-200px) rotate(45deg);
    transform: translateZ(-200px) rotate(45deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) rotate(0);
    transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}
</style>