<template>
  <article class="floating">
    <div class="floating__container">
      <transition name="fade">
        <ul
          v-if="menuIsActive"
          class="floating__container__menu"
        >
          <li class="floating__container__menu__item">
            <div class="floating__container__menu__item__tooltip">
              <p class="floating__container__menu__item__tooltip__text">
                Fale por Whatsapp
              </p>
            </div>
            <a
              href="https://api.whatsapp.com/send/?phone=5527995702015&text=UpCities&type=phone_number&app_absent=0"
              class="floating__container__menu__item__button"
              target="_blank"
              rel="noopener noreferrer"
            ><SvgElement
              name="Whatsapp"
              class="floating__container__menu__item__button__icon"
            /></a>
          </li>
          <li class="floating__container__menu__item">
            <div class="floating__container__menu__item__tooltip">
              <p class="floating__container__menu__item__tooltip__text">
                Formulário de contato
              </p>
            </div>
            <a
              class="floating__container__menu__item__button"
              @click.prevent="moveToContactForm"
            ><SvgElement
              name="FormIcon"
              class="floating__container__menu__item__button__icon"
            /></a>
          </li>
        </ul>
      </transition>
      <div class="floating__container__content">
        <a
          class="floating__container__content__button"
          :class="{ active: menuIsActive }"
          @click.prevent="toggleMenu"
        >
          <transition name="bounce">
            <SvgElement
              v-if="menuIsActive"
              name="Close"
              class="floating__container__content__button__icon"
            />
          </transition>
          <transition name="bounce">
            <SvgElement
              v-if="!menuIsActive"
              name="ChatIcon"
              class="floating__container__content__button__icon"
            /> </transition></a>
        <transition name="slide-fade">
          <p
            v-if="menuIsActive"
            class="floating__container__content__close"
          >
            Fechar
          </p>
          <div
            v-if="tooltipIsActive && !menuIsActive"
            class="floating__container__content__tooltip"
          >
            <p class="floating__container__content__tooltip__text">
              Sabemos que o seu tempo é valioso. Como podemos ajudar?
            </p>
          </div>
        </transition>
      </div>
      <transition name="fade">
        <div
          v-if="menuIsActive"
          class="floating__overlay"
        />
      </transition>
    </div>
  </article>
</template>

<script>
export default {
  name: 'FloatingMenu',
  data() {
    return {
      tooltipIsActive: false,
      menuIsActive: false
    };
  },

  mounted() {
    this.tooltipIsActive = true;
    this.showTooltip();
  },

  methods: {
    showTooltip() {
      setInterval(this.changeTooltipStatus, 10000);
    },
    changeTooltipStatus() {
      this.tooltipIsActive = !this.tooltipIsActive;
    },
    toggleMenu() {
      this.tooltipIsActive = false;
      this.menuIsActive = !this.menuIsActive;
    },
    moveToContactForm() {
      this.menuIsActive = false;
      let contactForm = document.getElementById('form');

      contactForm.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
</script>

<style lang="scss" scoped>
.floating {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 50;

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 2rem;

    &__content {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
      height: 3.75rem;
      position: relative;

      &__button {
        background-color: $primary;
        border-radius: 50%;
        min-width: 3.75rem;
        min-height: 100%;
        position: relative;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
        cursor: pointer;
        transition: all 300ms ease-in-out;

        &.active {
          background-color: $gray-text;
        }

        &__icon {
          color: $white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 55%;
        }
      }

      &__tooltip {
        background-color: $white;
        height: 100%;
        max-width: 17.3125rem;
        padding: 16px;
        border-radius: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
        position: relative;

        &::after {
          content: "";
          position: absolute;
          right: -0.625rem;
          top: 50%;
          transform: translate(0, -50%);
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid $white;
        }

        &__text {
          color: $gray-text;
          font-size: calc(#{$text-sm} + (16 - 14) * ((100vw - 18.75rem) / (1900 - 300)));
        }
      }

      &__close {
        font-weight: bold;
      }
    }

    &__menu {
      position: fixed;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 2rem;

      &__item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;

        &__tooltip {
          background-color: $white;
          border-radius: 16px;
          height: 2rem;
          padding: 0 1rem;
          display: flex;
          align-items: center;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

          &__text {
            font-weight: $font-bold;
            color: $gray-text;
          }
        }

        &__button {
          width: 3.75rem;
          height: 3.75rem;
          background-color: $white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
          cursor: pointer;

          &__icon {
            width: 1.75rem;
            height: 1.75rem;
            color: $primary;
            fill: $primary;
          }
        }
      }
    }

    &__content,
    &__menu {
      position: relative;
      z-index: $backdrop-item;
    }
  }

  &__overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(245, 245, 245, 0.6);
    top: 0;
    left: 0;
  }
}
</style>
