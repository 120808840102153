<template>
  <section
    id="form"
    class="banner"
  >
    <EmailModal
      :visible="showModal"
      @close="closeModal"
    />
    <h2 class="banner__title">
      Preencha o formulário e tenha o UpCities na sua Cidade
    </h2>
    <form class="banner__form">
      <div class="banner__form__group">
        <div
          class="input-group"
          :class="{ 'input-group--error': errors.name }"
        >
          <label
            class="input-group__label"
            for="name"
            :class="{ 'input-group__label--hover': form.name }"
          >Nome completo*</label>
          <div class="input-wrapper">
            <input
              id="name"
              v-model="form.name"
              class="input-group__input"
              type="text"
              name="name"
              required
            >
          </div>
          <span class="input-group__error">
            {{ errors.name }}
          </span>
        </div>
        <div
          class="input-group"
          :class="{ 'input-group--error': errors.email }"
        >
          <label
            class="input-group__label"
            for="email"
            :class="{ 'input-group__label--hover': form.email }"
          >E-mail*</label>
          <div class="input-wrapper">
            <input
              id="email"
              v-model="form.email"
              class="input-group__input"
              type="email"
              name="email"
              required
            >
          </div>
          <span class="input-group__error">
            {{ errors.email }}
          </span>
        </div>
        <div
          class="input-group"
          :class="{ 'input-group--error': errors.telephone }"
        >
          <label
            class="input-group__label"
            for="telephone"
            :class="{ 'input-group__label--hover': form.telephone }"
          >Telefone*</label>
          <div class="input-wrapper">
            <input
              id="telephone"
              v-model="form.telephone"
              class="input-group__input"
              type="tel"
              name="telephone"
              minlength="9"
              required
            >
          </div>
          <span class="input-group__error">
            {{ errors.telephone }}
          </span>
        </div>
      </div>
      <div class="banner__form__group">
        <div class="input-group--large">
          <div
            class="input-group"
            :class="{ 'input-group--error': errors.state }"
          >
            <label
              class="input-group__label"
              for="state"
              :class="{ 'input-group__label--hover': form.state }"
            >UF*</label>
            <select
              id="state"
              v-model="form.state"
              class="input-group__select"
              name="state"
              required
            >
              <option
                class="input-group__select__option"
                value="AC"
              >
                AC
              </option>
              <option
                class="input-group__select__option"
                value="AL"
              >
                AL
              </option>
              <option
                class="input-group__select__option"
                value="AP"
              >
                AP
              </option>
              <option
                class="input-group__select__option"
                value="AM"
              >
                AM
              </option>
              <option
                class="input-group__select__option"
                value="BA"
              >
                BA
              </option>
              <option
                class="input-group__select__option"
                value="CE"
              >
                CE
              </option>
              <option
                class="input-group__select__option"
                value="ES"
              >
                ES
              </option>
              <option
                class="input-group__select__option"
                value="GO"
              >
                GO
              </option>
              <option
                class="input-group__select__option"
                value="MA"
              >
                MA
              </option>
              <option
                class="input-group__select__option"
                value="MT"
              >
                MT
              </option>
              <option
                class="input-group__select__option"
                value="MS"
              >
                MS
              </option>
              <option
                class="input-group__select__option"
                value="MG"
              >
                MG
              </option>
              <option
                class="input-group__select__option"
                value="PA"
              >
                PA
              </option>
              <option
                class="input-group__select__option"
                value="PB"
              >
                PB
              </option>
              <option
                class="input-group__select__option"
                value="PR"
              >
                PR
              </option>
              <option
                class="input-group__select__option"
                value="PE"
              >
                PE
              </option>
              <option
                class="input-group__select__option"
                value="PI"
              >
                PI
              </option>
              <option
                class="input-group__select__option"
                value="RJ"
              >
                RJ
              </option>
              <option
                class="input-group__select__option"
                value="RN"
              >
                RN
              </option>
              <option
                class="input-group__select__option"
                value="RS"
              >
                RS
              </option>
              <option
                class="input-group__select__option"
                value="RO"
              >
                RO
              </option>
              <option
                class="input-group__select__option"
                value="RR"
              >
                RR
              </option>
              <option
                class="input-group__select__option"
                value="SC"
              >
                SC
              </option>
              <option
                class="input-group__select__option"
                value="SP"
              >
                SP
              </option>
              <option
                class="input-group__select__option"
                value="SE"
              >
                SE
              </option>
              <option
                class="input-group__select__option"
                value="TO"
              >
                TO
              </option>
              <option
                class="input-group__select__option"
                value="DF"
              >
                DF
              </option>
            </select>
            <span class="input-group__error">
              {{ errors.state }}
            </span>
          </div>
          <div
            class="input-group"
            :class="{ 'input-group--error': errors.city }"
          >
            <label
              class="input-group__label"
              for="city"
              :class="{ 'input-group__label--hover': form.city }"
            >Cidade*</label>
            <div class="input-wrapper">
              <input
                id="city"
                v-model="form.city"
                type="text"
                class="input-group__input"
                name="city"
                required
              >
            </div>
            <span class="input-group__error">
              {{ errors.city }}
            </span>
          </div>
        </div>
        <div
          class="input-group"
          :class="{ 'input-group--error': errors.message }"
        >
          <label
            class="input-group__label input-group__label--textarea"
            for="message"
          >Escreva sua mensagem</label>
          <textarea
            id="message"
            v-model="form.message"
            class="input-group__textarea"
            name="message"
            cols="40"
            rows="3"
            required
          />
          <span class="input-group__error">
            {{ errors.message }}
          </span>
        </div>
      </div>
      <div class="banner__form__submit">
        <input
          class="banner__form__submit__btn"
          :class="submitBtn.class"
          type="submit"
          :value="submitBtn.text"
          @click.prevent="submitForm()"
        >
      </div>
    </form>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import EmailModal from '@/components/common/EmailModal.vue';

export default {
  name: 'FormBanner',

  components: {
    EmailModal,
  },

  data() {
    return {
      submitBtn: {
        text: 'Enviar formulário',
        class: 'initial',
      },
      showModal: false,
      loading: false,

      form: {
        name: null,
        email: null,
        telephone: null,
        state: null,
        city: null,
        message: null,
      },

      errors: {
        name: null,
        email: null,
        telephone: null,
        state: null,
        city: null,
        message: null,
      },
    };
  },

  methods: {
    ...mapActions([
      'sendMail'
    ]),

    openModal() {
      this.showModal = true;
      document.querySelector('html').style.overflowY = 'hidden';
    },

    closeModal() {
      this.showModal = false;
      document.querySelector('html').style.overflowY = 'auto';
    },

    validadeForm() {
      if(!this.form.name) {
        this.errors.name = 'Este campo é obrigatório';
      } else {
        this.errors.name = null;
      }

      if(!this.form.email) {
        this.errors.email = 'Preencha com um e-mail válido';
      } else if(!this.form.email.includes('@')) {
        this.errors.email = 'Insira um e-mail válido';
      } else {
        this.errors.email = null;
      }

      if(!this.form.telephone) {
        this.errors.telephone = 'Preencha com um número de telefone válido';
      } else if(this.form.telephone < 9) {
        this.errors.telephone = 'Insira um telefone válido';
      } else {
        this.errors.telephone = null;
      }

      if(!this.form.state) {
        this.errors.state = 'Obrigatório';
      } else {
        this.errors.state = null;
      }

      if(!this.form.city) {
        this.errors.city = 'Este campo é obrigatório';
      } else {
        this.errors.city = null;
      }

      if(!this.form.message) {
        this.errors.message = 'Preencha com sua mensagem';
      } else {
        this.errors.message = null;
      }

      if(this.errors.name === null &&
        this.errors.email === null &&
        this.errors.telephone === null &&
        this.errors.state === null &&
        this.errors.city === null &&
        this.errors.message === null
      ) {

        return true;
      }

      return false;
    },

    cleanForm() {
      this.form.name = null;
      this.form.email = null;
      this.form.telephone = null;
      this.form.state = null;
      this.form.city = null;
      this.form.message = null;
    },

    async submitForm() {
      if(this.validadeForm() && !this.loading) {
        this.submitBtn.text = 'Enviando...';
        this.loading = true;

        try {
          await this.sendMail(this.form);
          this.submitBtn.text = 'Enviado!';
          this.submitBtn.class = 'success';
          this.loading = false;
          this.openModal();
          this.cleanForm();
        } catch (error) {
          this.submitBtn.text = 'Não foi possível enviar.';
          this.submitBtn.class = 'error';
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  padding: 2rem 4%;
  background-color: $green-100;

  @include screen(tablet-big-up) {
    padding: 2rem 8%;
  }

  @include screen(desktop-big-up) {
    padding: 2rem calc(50vw - 35rem);
  }

  &__title {
    font-size: 1.75rem;
    font-weight: $font-bold;
    display: block;
    max-width: 18.75rem;
    margin: 2rem auto;
    text-align: center;
    color: $gray-500;

    @include screen(tablet-big-up) {
      font-size: 2.5rem;
      max-width: 35.5rem;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: $gray-500;

    @include screen(tablet-big-up) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
    }

    &__group {
      width: 100%;
    }

    .input-group {
      width: 100%;
      display: block;
      padding: 1rem 0;

      &__label {
        display: block;
        width: 100%;
        position: relative;
        margin-bottom: 0;
        font-size: $text-base;
        bottom: -2rem;
        color: $gray-text;
        transition: bottom  0.2s ease-in-out;
        height: 1.5rem;

        @include screen(tablet-big-up) {
          font-size: $text-lg;
        }

        &--textarea {
          bottom: 0;
        }

        &--hover {
          bottom: 0;
          font-size: $text-xs;

          @include screen(tablet-big-up) {
            font-size: $text-sm;
          }
        }
      }

      &--error .input-group__label {
        color: $red-500;
      }

      &:focus-within {
        .input-group__label {
          bottom: 0;
          font-size: $text-xs;

          @include screen(tablet-big-up) {
            font-size: $text-sm;
          }

          &--textarea {
            font-size: $text-lg;
          }
        }
      }

      .input-wrapper {
        border-bottom: 1px solid $gray-200;

        .input-group--error .input-wrapper {
          display: flex;
          align-items: center;
          width: 100%;
          border-color: $red-500;

          &::after {
            position: relative;
            display: inline;
            content: "!";
            width: 1.625rem;
            height: 1.625rem;
            border-radius: $radius-full;
            background-color: $red-500;
            color: $white;
            font-weight: $font-bold;
            text-align: center;
          }
        }
      }

      &__input {
        display: block;
        width: 100%;
        padding: 0.5rem;
        height: 2.5rem;
        font-size: $text-base;
        color: $gray-text;
        background-color: $transparent !important;

        &:focus {
          outline: none;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          box-shadow: 0 0 0 30px $green-100 inset !important;
          -webkit-box-shadow: 0 0 0 30px $green-100 inset !important;
        }
      }

      &__select {
        display: block;
        width: 100%;
        padding: 0.5rem;
        height: 2.5rem;
        background-color: $transparent;
        border-bottom: 1px solid $gray-200;

        &:focus {
          outline: none;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          box-shadow: 0 0 0 30px $green-100 inset !important;
          -webkit-box-shadow: 0 0 0 30px $green-100 inset !important;
        }

        .input-group--error .input-group__select {
          border-color: $red-500;
        }
      }

      &__textarea {
        margin-top: 0.5rem;
        padding: 0.5rem;
        display: block;
        width: 100%;
        height: 6rem;
        resize: none;
        border: 1px solid $gray-200;
        border-radius: $radius-lg;
        background-color: $transparent;

        &:focus {
          outline: none;
        }

        .input-group--error .input-group__textarea {
          border-color: $red-500;
        }
      }

      &__error {
        position: absolute;
        margin-top: 0.1rem;
        font-size: $text-xs;
        color: $red-500;
      }
    }

    .input-group--large {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 1rem;

      @include screen(tablet-big-up) {
        margin-bottom: 2rem;
      }
    }

    &__submit {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;

      @include screen(tablet-big-up) {
        grid-column-start: 2;
      }

      &__btn {
        padding: 0.5rem 1.5rem;
        border-radius: $radius-lg;
        border: 1px solid $primary-btn;
        font-size: $text-xs;
        font-weight: $font-bold;
        text-transform: uppercase;

        @include screen(tablet-big-up) {
          width: fit-content;
          font-size: $text-lg;
          text-transform: none;
        }
      }

      .initial {
        background-color: $transparent;
        border-color: $primary-btn;
        color: $primary-btn;

        @include screen(tablet-big-up) {
          &:hover {
            cursor: pointer;
            background-color: $primary-btn;
            border-color: $primary-btn;
            color: $white;
          }
        }
      }

      .success {
        background-color: $primary-btn;
        border-color: $primary-btn;
        color: $white;
      }

      .error {
        background-color: $red-500;
        border-color: $red-500;
        color: $white;
      }
    }
  }
}
</style>