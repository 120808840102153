<template>
  <section
    id="partners"
    class="banner"
  >
    <article class="banner__main">
      <div class="lottie-wrapper">
        <div
          id="pins-mobile"
          class="lottie"
        />
        <div
          id="pins-web"
          class="lottie"
        />
      </div>
      <h2 class="banner__main__title">
        Contribuindo com cidades de todos os cantos do país.
      </h2>
    </article>
    <div class="banner__partners">
      <div class="banner__partners--inner">
        <span>
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-vila-velha.png"
            alt="Prefeitura de Vila Velha"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-aracruz.png"
            alt="Prefeitura de Aracruz"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-itapemirim.png"
            alt="Prefeitura Municipal de Itapemirim"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-cariacica.png"
            alt="Prefeitura de Cariacica"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-vitoria.png"
            alt="Prefeitura de Vitória"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-serra.png"
            alt="Prefeitura Municipal da Serra"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-presidente-kennedy.png"
            alt="Prefeitura Municipal de Presidente Kennedy"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-porto-real.png"
            alt="Prefeitura de Porto Real"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-piuma.png"
            alt="Prefeitura de Piúma"
            class="logo"
          >
        </span>
        <span>
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-vila-velha.png"
            alt="Prefeitura de Vila Velha"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-aracruz.png"
            alt="Prefeitura de Aracruz"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-itapemirim.png"
            alt="Prefeitura Municipal de Itapemirim"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-cariacica.png"
            alt="Prefeitura de Cariacica"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-vitoria.png"
            alt="Prefeitura de Vitória"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-serra.png"
            alt="Prefeitura Municipal da Serra"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-presidente-kennedy.png"
            alt="Prefeitura Municipal de Presidente Kennedy"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-porto-real.png"
            alt="Prefeitura de Porto Real"
            class="logo"
          >
          <img
            src="../../assets/img/prefeituras/logo-prefeitura-piuma.png"
            alt="Prefeitura de Piúma"
            class="logo"
          >
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import lottie from 'lottie-web';

export default {
  name: 'PatnersBanner',

  data() {
    return {
      pinsMobile: null,
      pinsWeb: null,
    };
  },

  mounted() {
    this.initLottieAnimations();
  },

  methods: {
    initLottieAnimations() {
      this.pinsMobile = lottie.loadAnimation({
        container: document.getElementById('pins-mobile'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('@/assets/animation/pins-mobile.json'),
      });

      this.pinsWeb = lottie.loadAnimation({
        container: document.getElementById('pins-web'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('@/assets/animation/pins-web.json'),
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.lottie-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .lottie {
    height: 100%;
    width: fit-content;

    svg {
      z-index: -1;
    }
  }

  #pins-mobile {
    display: block;

    @include screen(tablet-big-up) {
      display: none;
    }
  }

  #pins-web {
    display: none;

    @include screen(tablet-big-up) {
      display: block;
    }
  }
}

.banner {
  padding: 2rem 4%;

  @include screen(tablet-big-up) {
    padding: 4rem 8%;
  }

  &__main {
    width: 100%;
    position: relative;
    padding: 4rem 1rem;
    margin-bottom: 2rem;

    @include screen(tablet-big-up) {
      padding: 4.5rem 3rem;
    }

    &__title {
      width: 100%;
      text-align: center;
      color: $gray-text;
      font-size: $text-3xl;
      font-weight: $font-bold;
      line-height: 2.5rem;
      margin: auto;
      position: relative;

      @include screen(tablet-big-up) {
        font-size: 3.5rem;
        line-height: 5rem;
        max-width: 54rem;
        margin: auto;
      }
    }
  }

  &__partners {
    @include screen(tablet-big-up) {
      display: flex;
      align-items: center;
      height: 5rem;   // -> Deve possuir altura fixa
      width: 112.5rem;  // -> Deve possuir largura fixa e igual a soma
      // da largura de todas as logos incluindo as margens
      // no caso (largura da logo + 2 * margem) * número de logos
      // => (8.5rem + 2 * 2rem) * 9 = 112,5rem
      overflow: hidden;  // Necessário
      box-sizing: border-box;  // Necessário
      position: relative;  // Necessário
      margin: 0 auto;
      left: -8vw;  // Compensando o padding do banner

      @include screen(desktop-big-up) {
        left: 0;
      }
    }

    &--inner {
      @include screen(tablet-big-up) {
        display: block;  // Necessário
        width: 200%;  // Necessário
        position: absolute;  // Necessário
        margin: 2rem 0;  // Varia de acordo com o layout
        animation: marquee 100s linear infinite;  // Necessário (o intervalo de tempo define a velocidade)
      }

      /* &:hover {  // Opcional
        animation-play-state: paused;
      } */

      .logo {
        width: 8.5rem;
        margin: auto;

        @include screen(tablet-big-up) {
          display: inline-block;  // Necessário
          float: left;  // Necessário
          margin: 0 2rem;  // Varia de acordo com o layout
        }

        &:last-child {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }

    span {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 2.5rem;
      column-gap: 1rem;

      @include screen(tablet-big-up) {
        display: flex;
        align-items: center;
        column-gap: 0;
        float: left;
        width: 50%;
      }

      &:first-child {
        display: none;

        @include screen(tablet-big-up) {
          display: flex;
        }
      }
    }
  }
}

@keyframes marquee { // animação de "scroll infinito"
  0% { left: 0; }
  100% { left: -100%; }
}
</style>