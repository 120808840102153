<template>
  <div class="card__img">
    <img
      v-if="selected === 1"
      src="@/assets/img/slider/moduloAgendamento.png"
      :alt="title"
    >
    <img
      v-if="selected === 2"
      src="@/assets/img/slider/moduloCentralDeServicos.png"
      :alt="title"
    >
    <img
      v-if="selected === 3"
      src="@/assets/img/slider/moduloProcon.png"
      :alt="title"
    >
    <img
      v-if="selected === 4"
      src="@/assets/img/slider/mockupAplicativo.png"
      :alt="title"
    >
    <img
      v-if="selected === 5"
      src="@/assets/img/slider/moduloInternetPublica.png"
      :alt="title"
    >
    <img
      v-if="selected === 6"
      src="@/assets/img/slider/moduloUpbot.png"
      :alt="title"
    >
    <img
      v-if="selected === 7"
      src="@/assets/img/slider/moduloComercioLocal.png"
      :alt="title"
    >
  </div>
</template>

<script>
export default {
  name: 'CardImage',

  props: {
    selected: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    }
  },
};
</script>