<template>
  <nav
    id="header"
    class="secondLine"
  >
    <SvgElement
      name="LogoUpCities"
      class="secondLine__title"
    />
    <button
      class="secondLine__menu-btn"
      @click="openMenu()"
    >
      <SvgElement
        name="MenuMobile"
      />
    </button>
    <div
      id="header-menu"
      class="header-menu"
      :class="{ 'header-menu--open': menuIsOpen }"
    >
      <div class="header-menu__top">
        <SvgElement
          name="LogoUpCities"
          class="header-menu__top__logo"
        />
        <button
          class="header-menu__close-btn"
          @click="closeMenu()"
        >
          <SvgElement
            name="Close"
          />
        </button>
      </div>
      <ul class="header-menu__list">
        <li class="header-menu__list__item">
          <button
            @click="goTo('benefits')"
          >
            Benefícios
          </button>
        </li>
        <li class="header-menu__list__item">
          <button
            @click="goTo('services')"
          >
            Serviços
          </button>
        </li>
        <li class="header-menu__list__item">
          <button
            @click="goTo('partners')"
          >
            Clientes
          </button>
        </li>
        <!-- <li class="header-menu__list__item">
          <a
            href="https://uppersoft.com.br/?s=upcities"
            target="_blank"
            rel="noopener noreferrer"
          >Blog</a>
        </li> -->
      </ul>
      <button
        class="header-menu__link"
        @click="goTo('form')"
      >
        Quero na minha cidade
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'SecondLine',

  data() {
    return {
      menuIsOpen: false,
    };
  },

  created() {
    window.onresize = () => {
      if(this.menuIsOpen && screen.width > 1024)
        this.closeMenu();
    };
  },

  methods: {
    openMenu() {
      this.menuIsOpen = true;
      document.querySelector('html').style.overflowY = 'hidden';
    },

    closeMenu() {
      this.menuIsOpen = false;
      document.querySelector('html').style.overflowY = 'auto';
    },

    goTo(nav) {
      this.closeMenu();

      const options = {
        behavior: 'smooth',
        block: nav === 'services' || screen.width < 1024 ? 'start' : 'center'
      };

      document.querySelector(`#${nav}`).scrollIntoView(options);
    },
  }
};
</script>

<style lang="scss" scoped>
.secondLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 1rem 4%;
  transition: box-shadow 0.3s ease-in-out;

  @include screen(tablet-big-up) {
    padding: 1rem 8%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  @include screen(desktop-big-up) {
    padding: 1rem calc(50vw - 35rem);
  }

  &__title {
    color: $primaria-cities;

    @include screen(tablet-big-up) {
      height: 2rem;
      width: auto;
    }
  }

  &__menu-btn {
    font-size: $text-lg;

    @include screen(tablet-big-up) {
      display: none;
    }
  }

  .header-menu {
    position: fixed;
    top: 0;
    left: 100%;
    background-color: $primaria-cities;
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: grid;
    grid-template-rows: auto 1fr auto;
    row-gap: 3.75rem;
    -webkit-transition: left 0.4s ease-in-out;
    transition: left 0.4s ease-in-out;

    @include screen(tablet-big-up) {
      position: relative;
      display: flex;
      align-items: center;
      width: auto;
      height: auto;
      padding: 0;
      left: 0;
      background-color: $transparent;
      -webkit-transition: none;
      transition: none;
    }

    &__close-btn {
      @include screen(tablet-big-up) {
        display: none;
      }
    }

    &--open {
      left: 0;
    }

    &__top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $gray-bg;

      @include screen(tablet-big-up) {
        width: auto;
      }

      &__logo {
        @include screen(tablet-big-up) {
          display: none;
        }
      }
    }

    &__list {
      color: $gray-bg;

      @include screen(tablet-big-up) {
        display: flex;
        align-items: center;
      }

      &__item {
        font-size: $text-lg;
        margin-bottom: 2rem;

        @include screen(tablet-big-up) {
          color: $gray-text;
          margin-bottom: 0;
          margin-right: 3rem;
          border-bottom: 2px solid $transparent;
          outline: none;

          &:hover {
            color: $primary-btn;
            border-color: $primary-btn;
          }
        }

        button {
          outline: none;
        }
      }
    }

    &__link {
      width: 100%;
      text-align: center;
      padding: 0.75rem 0;
      background-color: $gray-100;
      border-radius: 0.5rem;
      font-size: $text-lg;
      font-weight: $font-bold;
      color: $primaria-cities;
      -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
      outline: none;

      @include screen(tablet-big-up) {
        width: auto;
        padding: 0.5rem 1rem;
        color: $primary-btn;
        border: 1px solid $primary-btn;
        background-color: $transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        &:hover {
          color: $white;
          background-color: $primary-btn;
          border: 1px solid $primary-btn;
        }
      }
    }
  }
}

.scrolled {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
</style>