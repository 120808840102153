<template>
  <section
    id="services"
    class="slider"
  >
    <h2 class="slider__title">
      Faça mais com o UpCities
    </h2>
    <h4 class="slider__subtitle">
      Uma plataforma completa, seja por aplicativo ou desktop, tudo personalizado para atender sua cidade.
    </h4>
    <div class="slider__content">
      <ul
        id="slider-nav"
        class="slider__content__nav"
      >
        <li
          id="nav-1"
          class="slider__content__nav__item"
          :class="{ 'slider__content__nav__item--selected': selected === 1 }"
        >
          <button @click="select(1, true)">
            Agendamento
          </button>
        </li>
        <li
          id="nav-2"
          class="slider__content__nav__item"
          :class="{ 'slider__content__nav__item--selected': selected === 2 }"
        >
          <button @click="select(2, true)">
            <span class="slider__content__nav__item--mobile">
              Central de Serviços
            </span>
            <span class="slider__content__nav__item--desktop">
              Central
            </span>
          </button>
        </li>
        <li
          id="nav-3"
          class="slider__content__nav__item"
          :class="{ 'slider__content__nav__item--selected': selected === 3 }"
        >
          <button @click="select(3, true)">
            Procon
          </button>
        </li>
        <li
          id="nav-4"
          class="slider__content__nav__item"
          :class="{ 'slider__content__nav__item--selected': selected === 4 }"
        >
          <button @click="select(4, true)">
            Aplicativo
          </button>
        </li>
        <li
          id="nav-5"
          class="slider__content__nav__item"
          :class="{ 'slider__content__nav__item--selected': selected === 5 }"
        >
          <button @click="select(5, true)">
            <span class="slider__content__nav__item--mobile">
              Internet Pública
            </span>
            <span class="slider__content__nav__item--desktop">
              Wi-fi Público
            </span>
          </button>
        </li>
        <li
          id="nav-6"
          class="slider__content__nav__item"
          :class="{ 'slider__content__nav__item--selected': selected === 6 }"
        >
          <button @click="select(6, true)">
            Chatbot
          </button>
        </li>
        <li
          id="nav-7"
          class="slider__content__nav__item"
          :class="{ 'slider__content__nav__item--selected': selected === 7 }"
        >
          <button @click="select(7, true)">
            Comércio
          </button>
        </li>
      </ul>
      <div
        id="slider"
        class="slider__content__cards"
      >
        <div
          id="slide-1"
          class="slide"
        >
          <Card :selected="1" />
        </div>
        <div
          id="slide-2"
          class="slide"
        >
          <Card :selected="2" />
        </div>
        <div
          id="slide-3"
          class="slide"
        >
          <Card :selected="3" />
        </div>
        <div
          id="slide-4"
          class="slide"
        >
          <Card :selected="4" />
        </div>
        <div
          id="slide-5"
          class="slide"
        >
          <Card :selected="5" />
        </div>
        <div
          id="slide-6"
          class="slide"
        >
          <Card :selected="6" />
        </div>
        <div
          id="slide-7"
          class="slide"
        >
          <Card :selected="7" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Card from '@/components/slider/SliderCard.vue';

export default {
  name: 'Slider',

  components: {
    Card,
  },

  data() {
    return {
      selected: 1,
      numberOfSlides: 7,
      userInteracted: false,
    };
  },

  created() {
    setInterval(this.nextSlide, 10000);
  },

  methods: {
    select(slideNo, user) {
      this.selected = slideNo;
      this.userInteracted = user;

      const nav = document.querySelector('#slider-nav');
      const selectedItem = document.querySelector(`#nav-${slideNo}`);
      const slider = document.querySelector('#slider');
      const selectedSlide = document.querySelector(`#slide-${slideNo}`);

      nav.scrollLeft = selectedItem.offsetLeft;
      slider.scrollLeft = selectedSlide.offsetLeft;
    },

    nextSlide() {
      if(!this.userInteracted)
        this.selected < this.numberOfSlides ? this.selected = this.selected + 1 : this.selected = 1;

      this.select(this.selected, false);
    },
  },
};
</script>

<style lang="scss" scoped>
.slider {
  width: 100%;
  padding: 4rem 4% 0;

  @include screen(tablet-big-up) {
    padding: 5rem 8% 0;
  }

  &__title {
    color: $gray-500;
    font-size: $text-3xl;
    font-weight: $font-bold;
    margin-bottom: 1rem;
    width: 100%;

    @include screen(tablet-big-up) {
      font-size: 2.5rem;
      text-align: center;
      margin: 0 auto 1rem;
    }
  }

  &__subtitle {
    color: $gray-500;
    font-size: $text-base;
    margin-bottom: 3rem;
    width: 100%;

    @include screen(tablet-big-up) {
      font-size: $text-lg;
      text-align: center;
      margin: 0 auto 3rem;
      max-width: 30rem;
    }
  }

  &__content {
    background-color: $transparent;

    @include screen(tablet-big-up) {
      background-color: $green-100;
      border-radius: $radius-lg;
      padding: 2.5rem;
      max-width: 70rem;
      margin: auto;
    }

    &__nav {
      width: 100%;
      display: flex;
      align-items: center;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      box-sizing: border-box;
      margin-bottom: 1.5rem;

      @include screen(tablet-big-up) {
        justify-content: space-between;
      }

      &::-webkit-scrollbar {
        height: 0;
        opacity: 0;
      }

      &__item {
        white-space: nowrap;
        margin-right: 1rem;
        border-bottom: 3px solid $transparent;
        scroll-snap-align: start;

        @include screen(tablet-big-up) {
          margin-right: 0;
        }

        button {
          color: $gray-500;
          font-weight: $font-bold;
          font-size: $text-base;
          outline: none;
        }

        &--selected {
          border-color: $primary-btn;
        }

        &--mobile {
          display: inherit;

          @include screen(tablet-big-up) {
            display: none;
          }
        }

        &--desktop {
          display: none;

          @include screen(tablet-big-up) {
            display: inherit;
          }
        }
      }
    }

    &__cards {
      width: 100%;
      display: flex;
      align-items: center;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        height: 0;
        opacity: 0;
      }

      .slide {
        box-sizing: border-box;
        background-color: $green-100;
        border-radius: $radius-lg;
        width: 92vw;
        display: block;
        scroll-snap-align: start;
        align-self: normal;
        flex-shrink: 0;

        @include screen(tablet-big-up) {
          width: calc(84vw - 5rem);
          max-width: 65rem;
        }
      }
    }
  }
}
</style>