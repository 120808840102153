import axios from 'axios';

// --- Config

// Common
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

// --- Instances

// E-mail config
const api = axios.create({
  baseURL: 'https://formsubmit.co',
  // timeout: 30000,
});

export {
  api
};