<template>
  <footer class="footer">
    <SvgElement
      name="LogoUpCities"
      class="footer__logo"
    />

    <button
      class="footer__button"
      @click="goTo('benefits')"
    >
      Benefícios
    </button>

    <button
      class="footer__button"
      @click="goTo('services')"
    >
      Serviços
    </button>

    <button
      class="footer__button"
      @click="goTo('partners')"
    >
      Clientes
    </button>

    <!-- <button
      class="footer__button"
      @click="goTo('partners')"
    >
      Blog
    </button> -->

    <router-link
      class="footer__button"
      :to="{ name: 'PrivacyPolicyUpCities' }"
    >
      Política de Privacidade
    </router-link>

    <router-link
      class="footer__button"
      :to="{ name: 'AntiCorruptionPolicy' }"
    >
      Política Anticorrupção
    </router-link>

    <a
      href="https://api.whatsapp.com/send/?phone=5527995702015&text=UpCities&type=phone_number&app_absent=0"
      target="_blank"
      rel="noopener noreferrer"
      class="footer__link"
    >
      <SvgElement
        class="footer__link__icon"
        name="Whatsapp"
      />
      <span class="footer__link__text">
        <span>
          Dúvidas?
        </span>
        <span>
          Estamos no WhatsApp
        </span>
      </span>
    </a>
  </footer>
</template>

<script>
export default {
  name: 'Footer',

  methods: {
    goTo(nav) {
      const options = {
        behavior: 'smooth',
        block: nav === 'services' ? 'start' : 'center'
      };

      document.querySelector(`#${nav}`).scrollIntoView(options);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 1rem 4%;
  white-space: nowrap;

  @include screen(tablet-big-up) {
    align-content: start;
    padding: 2rem 8%;
    grid-template-columns: auto 1fr 1fr 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 1rem;
    column-gap: max(1rem, 6%);
  }

  &__logo {
    margin: auto;
    color: $primaria-cities;
    height: 2.5rem;
    width: auto;
    box-sizing: content-box;
    padding: 1rem;

    @include screen(tablet-big-up) {
      padding: 0 2rem 0 0;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }

  &__button {
    padding: 1rem;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $gray-border;
    color: $gray-text;
    font-size: $text-sm;
    transition: all ease-in-out 0.2s;

    @include screen(tablet-big-up) {
      padding: 0;
      border: none;
      text-align: left;
      font-size: $text-base;
    }

    &:hover {
      color: $primaria-cities;
    }

    &:focus {
      outline: none;
      color: $primaria-cities;
      text-decoration: underline;
    }

    &:nth-child(2n) {
      @include screen(tablet-big-up) {
        grid-row-start: 1;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1.5rem;
    color: $primaria-cities;
    font-weight: $font-bold;
    cursor: pointer;

    @include screen(tablet-big-up) {
      justify-content: right;
      padding: 0;
      grid-column-start: 5;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: none;
      text-decoration: underline;
    }

    &__icon {
      height: 1.5rem;
      width: auto;
      box-sizing: content-box;
      padding: 0.5rem;
      border-radius: 100%;
      overflow: visible;
      background-color: $primary-light;
    }

    &__text {
      display: inline;

      :nth-child(n) {
        display: block;
      }

      @include screen(tablet-big-up) {
        display: block;
        text-align: left;
      }
    }
  }
}
</style>