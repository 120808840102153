<template>
  <section
    id="numbers"
    class="banner"
  >
    <article class="banner__card">
      <h3 class="banner__card__title">
        {{ downloads }} mil
      </h3>
      <p class="banner__card__text">
        downloads
      </p>
    </article>
    <article class="banner__card">
      <h3 class="banner__card__title">
        {{ users }} mil
      </h3>
      <p class="banner__card__text">
        usuários cadastrados
      </p>
    </article>
    <article class="banner__card">
      <h3 class="banner__card__title">
        {{ services }} milhões
      </h3>
      <p class="banner__card__text">
        serviços realizados
      </p>
    </article>
  </section>
</template>

<script>
export default {
  name: 'NumbersBanner',

  data() {
    return {
      target: {
        downloads: 238,
        users: 542,
        services: 3,
      },

      downloads: 0,
      users: 0,
      services: 0,
    };
  },

  created() {
    window.onscroll = () => {
      if(this.downloads !== this.target.downloads) {
        const banner = document.querySelector('#numbers').getBoundingClientRect();

        if(banner.top < (window.innerHeight - 250)) {
          this.countUp();
        }
      }

      if(window.scrollY > 0) {
        document.querySelector('#header').classList.add('scrolled');
        } else {
        document.querySelector('#header').classList.remove('scrolled');
      }
    };
  },

  methods: {
    countUp() {
      const updateCounter = () => {
        const inc = {
          downloads: this.target.downloads / 500,
          users: this.target.users / 500,
          services: this.target.services / 500,
        };

        if (this.downloads < this.target.downloads) {
          this.downloads = Math.ceil(this.downloads + inc.downloads);
        }
        if (this.users < this.target.users) {
          this.users = Math.ceil(this.users + inc.users);
        }
        if (this.services < this.target.services) {
          this.services = Math.ceil(this.services + inc.services);
        }

        if (this.downloads < this.target.downloads ||
        this.users < this.target.users ||
        this.services < this.target.services) {
          setTimeout(updateCounter, 10);
        } else {
          this.downloads = this.target.downloads;
          this.users = this.target.users;
          this.services = this.target.services;
        }
      };

      updateCounter();
    },
  }
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  padding: 4rem 4%;
  background-color: $green-100;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-columns: auto;
  row-gap: 4rem;

  @include screen(tablet-big-up) {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 0;
    column-gap: 4rem;
    padding: 4rem 8%;
  }

  @include screen(desktop-big-up) {
    padding: 4rem calc(50vw - 35rem);
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      color: $primary-btn;
      font-size: 3.5rem;
      font-weight: $font-bold;
      white-space: nowrap;
    }

    &__text {
      color: $gray-400;
      font-size: $text-xl;
    }
  }
}
</style>