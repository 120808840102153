<template>
  <section
    id="benefits"
    class="benefits-banner"
  >
    <article class="card">
      <SvgElement
        class="card__icon"
        name="ServicosContaUnica"
      />
      <h3 class="card__title">
        Serviços com Conta Única
      </h3>
      <p class="card__text">
        Disponibilize todos os serviços da cidade com uma conta única.
        Mais praticidade para o cidadão e controle para a gestão pública.
      </p>
    </article>
    <article class="card">
      <SvgElement
        class="card__icon"
        name="SegurancaCidadao"
      />
      <h3 class="card__title">
        Segurança para o cidadão
      </h3>
      <p class="card__text">
        Dados protegidos para uma gestão pública mais eficiente, dinâmica e próxima do cidadão.
      </p>
    </article>
    <article class="card">
      <SvgElement
        class="card__icon"
        name="EscalabilidadeServicos"
      />
      <h3 class="card__title">
        Escalabilidade de Serviços
      </h3>
      <p class="card__text">
        Uma plataforma completa e adaptável para cidades com diferentes tamanhos e quantidade de serviços.
      </p>
    </article>
    <article class="card">
      <SvgElement
        class="card__icon"
        name="ComunicacaoRapida"
      />
      <h3 class="card__title">
        Comunicação rápida e direta com o cidadão
      </h3>
      <p class="card__text">
        Aplicativo municipal para conectar a prefeitura com o cidadão. Um canal direto para avisos e notificações.
      </p>
    </article>
  </section>
</template>

<script>
export default {
  name: 'BenefitsBanner',
};
</script>

<style lang="scss" scoped>
.benefits-banner {
  background-color: $green-200;
  padding: 4rem 4%;
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: auto auto auto auto;
  row-gap: 2.5rem;

  @include screen(tablet-big-up) {
    grid-template-columns: auto auto auto auto;
    column-gap: 2.5rem;
    grid-template-rows: 1fr;
    row-gap: 0;
    padding: 5rem 8%;
  }

  @include screen(desktop-big-up) {
    padding: 5rem calc(50vw - 35rem);
  }

  .card {
    @include screen(tablet-big-up) {
      &:first-child {
        .card__title {
          width: 9.5rem;
        }
      }

      &:nth-child(2) {
        .card__title {
          width: 11rem;
        }
      }

      &:nth-child(3) {
        .card__title {
          width: 10rem;
        }
      }

      &:nth-child(4) {
        .card__title {
          width: 15.5rem;
        }
      }
    }

    &__icon {
      margin-bottom: 1rem;

      @include screen(tablet-big-up) {
        margin-bottom: 1.5rem;
        width: 4.375rem;
        height: 4.375rem;
      }
    }

    &__title {
      color: $gray-text;
      font-size: $text-lg;
      font-weight: $font-bold;
      margin-bottom: 1rem;

      @include screen(tablet-big-up) {
        font-size: $text-2xl;
        margin-bottom: 1.5rem;
      }
    }

    &__text {
      color: $gray-text;
      font-size: $text-base;

      @include screen(tablet-big-up) {
        font-size: $text-lg;
      }
    }
  }
}
</style>