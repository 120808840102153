<template>
  <section class="banner">
    <div class="banner__text">
      <h1 class="banner__text__title">
        Inovação e praticidade para sua cidade
      </h1>
      <p class="banner__text__info">
        O UpCities é um serviço completo que facilita
        o acesso do cidadão aos serviços públicos e
        transforma a cidade através da inovação, praticidade e tecnologia.
      </p>
      <button
        class="banner__text__btn"
        @click="goTo('form')"
      >
        Quero contratar
        <SvgElement
          name="ArrowNext"
          class="banner__text__btn__icon"
        />
      </button>
    </div>
    <img
      src="@/assets/img/mockupNovoSiteCities.png"
      alt="AppMockup"
      class="banner__image"
    >
  </section>
</template>

<script>
export default {
  name: 'MainBanner',

  methods: {
    goTo(nav) {
      document.querySelector(`#${nav}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  margin-top: 54px; // TODO
  width: 100%;
  padding: 3.5rem 4%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include screen(tablet-big-up) {
    margin-top: 77px;
    padding: 0 8%;
    flex-direction: row;
    justify-content: center;
  }

  &__text {
    @include screen(tablet-big-up) {
      max-width: 28rem;
      margin-right: 9rem;
    }

    &__title {
      color: $gray-text;
      font-size: $text-3xl;
      font-weight: $font-bold;
      margin-bottom: 1rem;
      line-height: 2.5rem;

      @include screen(tablet-big-up) {
        font-size: 3.5rem;
        line-height: 5rem;
      }
    }

    &__info {
      font-size: $text-base;
      color: $gray-text;
      margin-bottom: 1rem;

      @include screen(tablet-big-up) {
        margin-bottom: 2rem;
        font-size: $text-lg;
      }
    }

    &__btn {
      display: flex;
      width: fit-content;
      align-items: center;
      font-size: $text-base;
      font-weight: $font-bold;
      color: $primary-btn;
      border-bottom: 2px solid $transparent;
      outline: none;

      &:hover {
        border-color: $primary-btn;
      }

      @include screen(tablet-big-up) {
        font-size: $text-xl;
      }

      &__icon {
        margin-left: 0.5rem;
        height: $text-base;
      }
    }
  }

  &__image {
    @include screen(tablet-big-up) {
      max-width: 31.25rem;
    }
  }
}
</style>
