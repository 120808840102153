<template>
  <header class="header">
    <!-- <FirstLine /> -->
    <SecondLine />
  </header>
</template>

<script>
// import FirstLine from '@/components/common/HeaderFirstLine.vue';
import SecondLine from '@/components/common/HeaderSecondLine.vue';

export default {
  name: 'Header',

  components: {
//    FirstLine,
    SecondLine,
  }
};
</script>

<style lang="scss" scoped>
.header {
  width: 100vw;
  margin: 0;
  position: fixed;
  top: 0;
  z-index: 30;
}
</style>
